import axios from 'axios';
import env from '../env';
import { getCookie } from 'pa_kit/services/manage_cookies';

const baseURL = env.BACKEND_BASE_URL + 'api/';
const servicesURL = env.SPORTELLO_BASE_URL + 'backend/api/';

const http = axios.create({
	baseURL: baseURL,
	headers: {
        'Content-Type': 'application/json',
		'X-CSRFToken': getCookie('csrftoken')
    },
	withCredentials: true
});

export const httpServices = axios.create({
    baseURL: servicesURL,
    headers: {
      "Content-type": "application/json",
      'X-CSRFToken': getCookie('csrftoken')
    },
    withCredentials: true
});

export default http;