/* eslint-disable import/no-anonymous-default-export */
import http, { httpServices } from './http';
import { IMUCategoryClass } from 'apps/imu_categories/models/imu_category';
import { ActivityClass } from 'apps/activities/models/activity';


class ApiService {

    //Get IMU Category List
    async getIMUCategoryList() {
        let result = await http.get('imu_categories/');
        return result.data.map((data) => new IMUCategoryClass(data)); 
    }

    //Get IMU Category
    async getIMUCategory(slug) {
        let result = await http.get('imu_categories/' + slug);
        return result.data; 
    }

    //Generate F24
    async generateF24(formData) {
        let result = await http.post('print-f24/', { ...formData });
        return result;
    }

    // Get Activities List
    async getActivities() {        
        let result = await http.get('activities/');       
        return result.data.map((data) => new ActivityClass(data));
    }

    //Create IMU Category
    async createIMUCategory(formData) {
        let result = await http.post('imu_categories/', {...formData });
        return result;
    }

    //Edit IMU Category
    async editIMUCategory(slug, formData) {
        let result = await http.patch('imu_categories/' + slug + '/', {...formData });
        return result;
    }

    //Delete IMU Category
    async deleteIMUCategory(slug) {
        let result = await http.delete('imu_categories/' + slug + '/');
        return result;
    }

    //Get IMU Settings
    async getIMUSettings() {
        let result = await http.get('imu_settings/');
        return result.data[0];
    }

    //Edit IMU Settings
    async editIMUSettings(id, formData) {
        let result = await http.patch('imu_settings/' + id + '/', {...formData });
        return result;
    }

    //Submit Request to Sportello
    async submitRequest(requestFormData) {
        let result = await httpServices.post('requests/', {...requestFormData });
        return result;
    }

    //Upload File to Sportello
    // async uploadFile(file) {
    //     const formData = new FormData();
        
    //     formData.append("file", file);

    //     const filenameHeaderValue = 'inline;filename=' + encodeURIComponent(file.name);

    //     const result = await httpServices.put('/file/upload/', formData, 
    //         {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 'Content-Disposition': filenameHeaderValue,
    //                 "Access-Control-Allow-Origin": "*"
    //             }
    //         }
    //     );
    //     return result.data;
    // }

    async uploadFileFromBlob(blob, filename) {
        try {
            const file = new File([blob], filename, { type: "application/pdf" });
    
            const formData = new FormData();
            formData.append("file", file);
    
            const filenameHeaderValue = 'inline;filename=' + encodeURIComponent(file.name);
                
            const result = await httpServices.put('/files/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': filenameHeaderValue
                }
            });
    
            return result.data;
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }
}

export default new ApiService();
